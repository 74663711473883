import { Controller } from 'stimulus'
import Glide from '@glidejs/glide'

export default class extends Controller {
  connect () {
    if (this.element) {
      new Glide(this.element, {
        type: 'carousel',
        perView: 3,
        gap: 15,
        autoplay: 4000,
        breakpoints: {
          1024: { perView: 2 },
          640: { perView: 1 }
        }
      }).mount()
    }
  }
}
