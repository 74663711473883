import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['name', 'email']

  add() {
    this.nameTarget.value = this.name
    this.emailTarget.value = this.email
  }

  get name() {
    return this.data.get('userName')
  }

  get email() {
    return this.data.get('userEmail')
  }
}
