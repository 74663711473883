import { Controller } from 'stimulus'
import Glide from '@glidejs/glide'

export default class extends Controller {
  connect () {
    if (this.element) {
      new Glide(this.element, {
        type: 'carousel',
        perView: 4,
        gap: 0,
        autoplay: 4000,
        breakpoints: {
          640: { perView: 2 }
        }
      }).mount()
    }
  }
}
