import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['form', 'checkbox']

  connect() {
    this.toggle()
  }

  toggle() {
    if (this.checkboxTarget.checked) {
      this.formTarget.classList.remove('hidden')
    } else {
      this.formTarget.classList.add('hidden')
    }
  }
}
