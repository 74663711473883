import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['alert']

  change(event) {
    const el = event.currentTarget
    const text = el.options[el.selectedIndex].text

    if (text.includes('Date Passed')) {
      this.alertTarget.classList.remove('hidden')
    } else {
      this.alertTarget.classList.add('hidden')
    }
  }
}
