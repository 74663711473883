import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['dropdown']

  toggle (event) {
    event.currentTarget.classList.toggle('active')
    this.dropdownTarget.classList.toggle('hidden')
  }
}
