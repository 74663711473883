import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['status']

  connect () {
    const input = document.querySelector('#course_draft')
    this.statusTarget.setAttribute('data-css-class', input.checked)
  }

  toggle (event) {
    this.statusTarget.setAttribute(
      'data-css-class',
      event.currentTarget.checked
    )
  }
}
