import Rails from 'rails-ujs'
import * as ActiveStorage from 'activestorage'
import Turbolinks from 'turbolinks'
import SweetScroll from 'sweet-scroll'

import 'controllers'
import './scripts/ga-page-tracking.js'

import '../css/application.scss'

// require("custom/main")

require.context('../images', true)

Rails.start()
ActiveStorage.start()
Turbolinks.start()

document.addEventListener('turbolinks:load', () => {
  new SweetScroll()
})