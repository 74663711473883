import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['form', 'input', 'results']

  typing () {
    let value = this.inputTarget.value
    let results = this.searchAgainst.filter(function (searchData) {
      for (let searchTerm of searchData.searchTerms) {
        if (searchTerm.toLowerCase().includes(value.toLowerCase()))
          return searchData
      }
    })

    value && results.length > 0
      ? (this.resultsTarget.innerHTML = this.render(results))
      : (this.resultsTarget.innerHTML = '')
  }

  render (data) {
    return `
      <div class="autocomplete-nav">
        ${data.map(item => this.buttonGenerator(item)).join('')}
      </div>
    `
  }

  buttonGenerator (item) {
    let searchTerms = item.searchTerms.filter(value => value !== item.title)

    if (searchTerms.length > 0) {
      return `
        <button type="button" data-action="autocomplete#attach" data-value="${
          item.title
        }" class="autocomplete-link">
          ${item.title}
          <small>Related Searches: ${searchTerms.join(', ')}</small>
        </button>
      `
    } else {
      return `
        <button type="button" data-action="autocomplete#attach" data-value="${item.title}" class="autocomplete-link">
          ${item.title}
        </button>
      `
    }
  }

  attach (event) {
    this.inputTarget.value = event.currentTarget.dataset.value
    this.formTarget.submit()
  }

  get searchAgainst () {
    return JSON.parse(this.data.get('courses'))
  }
}
